// This file is generated by yarn codegen, PLEASE DO NOT EDIT!
/* eslint-disable */

import * as Types from '../@graph/types/global.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type OnlineStatusSubscriptionSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type OnlineStatusSubscriptionSubscription = { __typename?: 'Subscription', onlineStatus: { __typename?: 'OnlineStatusSubscriptionPayload', user: { __typename: 'User', id: string, lastActiveAt: any | null, lastActiveAgoText: string | null, isOnline: boolean | null } | null } };


export const OnlineStatusSubscriptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"OnlineStatusSubscription"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"onlineStatus"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"lastActiveAt"}},{"kind":"Field","name":{"kind":"Name","value":"lastActiveAgoText"}},{"kind":"Field","name":{"kind":"Name","value":"isOnline"}}]}}]}}]}}]} as unknown as DocumentNode<OnlineStatusSubscriptionSubscription, OnlineStatusSubscriptionSubscriptionVariables>;
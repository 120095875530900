import {
  useSubscription as apolloUseSubscription,
  DocumentNode,
  TypedDocumentNode,
  SubscriptionHookOptions,
  OperationVariables,
} from '@apollo/client'

import useSubscriptionApolloClient from './useSubscriptionApolloClient'

// This hook functions exactly like apollo's useSubscription. The only difference is that it will use a separate apollo client
const useSubscription = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionHookOptions<TData, TVariables>
) => {
  const { subscriptionClient, isLoading } = useSubscriptionApolloClient()

  return apolloUseSubscription(subscription, {
    ...options,
    client: subscriptionClient!,
    skip: isLoading || !subscriptionClient || options?.skip,
  })
}

export default useSubscription

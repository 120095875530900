import { ApolloClient, ApolloLink, useApolloClient } from '@apollo/client'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { useEffect, useState } from 'react'

import { captureSentryException } from 'app/utils'

import { hasSubscriptionOperation } from './utils'

// This hook creates an instance of apollo client that is only used for subscription operations
const useSubscriptionApolloClient = () => {
  const [subscriptionClient, setSubscriptionClient] = useState<ApolloClient<object> | null>(null)

  const [actionCable, setActionCable] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(true)
  const apolloClient = useApolloClient()
  /**
   * Next JS will try to load actioncable during server side rendering if you simply import it like any other library
   * This will cause an error, as there is code in actioncable that references window object without checking if it is defined or not
   *
   * To make sure it doesn't happen, we need to load actioncable client-side
   * Loading actioncable in async function and running the function inside useEffect ensures that the library is only loaded client-side
   */
  const loadActionCable = async () => {
    const actionCableLib = await import('actioncable')
    setActionCable(actionCableLib as any)
    setIsLoading(false)
  }

  useEffect(() => {
    loadActionCable()
  }, [])

  useEffect(() => {
    if (isLoading || !actionCable) return

    const cable = actionCable.createConsumer(process.env.NEXT_PUBLIC_NL_ACTIONCABLE_SERVER_URL)
    const actionCableLink = new ActionCableLink({ cable })

    const fallbackLink = new ApolloLink((operation, _forward) => {
      console.warn('This apollo client can only be used for subscription operations')
      captureSentryException(
        `Invalid operation for subscription client: ${operation.operationName}`
      )
      return null
    })

    const splitLink = ApolloLink.split(
      (operation) => hasSubscriptionOperation(operation),
      actionCableLink,
      fallbackLink
    )

    const client = new ApolloClient({
      name: 'nl-next subscription apollo client',
      version: process.env.NEXT_PUBLIC_REVISION,
      link: splitLink,
      cache: apolloClient.cache,
      connectToDevTools: true,
    })

    setSubscriptionClient(client)
  }, [actionCable, apolloClient, isLoading])

  return { subscriptionClient, isLoading }
}

export default useSubscriptionApolloClient
